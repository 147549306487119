// src/Modal.js
import React from "react";
import "./Modal.css";

const Modal1 = ({ isOpen, onClose, score, image, scoreText }) => {
  if (!isOpen) return null;

  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  console.log(image);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Copy and Share!!!</h2>
        <img
          onContextMenu={handleContextMenu}
          src={image}
          alt="Game Over"
          width="250"
          height="250"
        />
        <p>
          <a href={score}>{score}</a>
          <br />
          Click the button to copy url and send to your lover in instagram,
          whatsapp or facebook to surprise your lover
        </p>
        <button onClick={onClose}>Click here to copy</button>
      </div>
    </div>
  );
};

export default Modal1;
