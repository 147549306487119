import React, { useState } from "react";
import axios from "axios";
import "./ImageUpload.css";
import Modal1 from "./Modal1";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [surpriseUrl, setSurpriseUrl] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const copyToClipboard = () => {
    if (surpriseUrl) {
      navigator.clipboard
        .writeText(surpriseUrl)
        .then(() => {
          alert(
            "URL copied, now you can share in facebook, instagram or whatsapp to surprise your crush!!"
          );
        })
        .catch((err) => {
          console.error("Failed to copy URL: ", err);
        });
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setUploadStatus("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedImage) {
      setUploadStatus("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("api_key", "ad7ce2574044201f70fe5cb0b7e91221");

    try {
      const response = await axios.post(
        "https://api.imghippo.com/v1/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      setUploadStatus("Upload successful!");
      setUploadedImageUrl(response.data.data.url);
      axios
        .post(
          "https://parseapi.back4app.com/classes/urldata",
          {
            urlstring: response.data.data.url,
          },
          {
            headers: {
              "X-Parse-Application-Id":
                "qTqzBMokNawHRaEDVg8ltzebXRFJkDYVjArDrQLL",
              "X-Parse-REST-API-Key":
                "RvUd2s0pD77d4vZ4SSbeBhDDkbeKmNf5u4H2WhIL",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data._id);
        })
        .catch((err) => {
          console.log(err);
        });
      setSurpriseUrl(
        "https://playmebro.site/wish?url=".concat(response.data.data.url)
      );
      setShowPopup(true);
    } catch (error) {
      setUploadStatus("Upload failed. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="image-upload-container">
      <h1>Upload image to surprise your lover</h1>
      <form onSubmit={handleSubmit}>
        <div className="file-input-container">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="file-input"
          />
          <label htmlFor="file-input" className="file-input-label">
            Choose Image
          </label>
        </div>
        {previewImage && (
          <div className="image-preview">
            <img src={previewImage} alt="Preview" />
          </div>
        )}
        <button type="submit" className="submit-button">
          Upload Image
        </button>
      </form>
      {uploadStatus && <p className="upload-status">{uploadStatus}</p>}

      {
        <Modal1
          isOpen={showPopup}
          score={surpriseUrl}
          image={uploadedImageUrl}
          scoreText={uploadedImageUrl}
          onClose={copyToClipboard}
        />
      }
    </div>
  );
};

export default ImageUpload;
