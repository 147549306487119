import React, { useState, useEffect } from "react";
import Post from "./Post";
import axios from "axios";
import { post } from "jquery";

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const doLoad = async () => {
    try {
      const response = await axios.get(
        "https://parseapi.back4app.com/classes/imgurls",
        {
          headers: {
            "X-Parse-Application-Id":
              "qTqzBMokNawHRaEDVg8ltzebXRFJkDYVjArDrQLL",
            "X-Parse-REST-API-Key": "RvUd2s0pD77d4vZ4SSbeBhDDkbeKmNf5u4H2WhIL",
            "Content-Type": "application/json",
          },
        }
      );
      setPosts(response.data.results); // Ensure data format matches expectation
    } catch (error) {
      console.error("Error fetching JSON:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    doLoad();
  }, []);

  return (
    <div
      style={{
        fontFamily: "'Arial', sans-serif",
        textAlign: "center",
        height: "100vh",
        margin: 0,
        padding: "20px",
      }}
    >
      <h1>Hot Aunties...</h1>
      {posts == null ? (
        <p>Loading...</p>
      ) : (
        posts.map((post, index) => <Post key={index} image={post.urldata} />)
      )}
    </div>
  );
};

export default Feed;
