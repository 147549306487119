import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ShootingGame from "./Games/Shooting/ShootingGame";
import Christmas from "./Games/Christmas/Christmas";
import StoryPage from "./Story/Story1/StoryPage";

import App from "./App";
import Home from "./Home";
import Feed from "./Feed/Feed";
import FeedAd from "./Feed/FeedAd";
import ImageUpload from "./Games/Wish/ImageUpload";
import ImageUploadAd from "./Games/Wish/ImageUploadAd";
import ValentinesDayPage from "./Games/Wish/ValentinesDayPage";
const RouterApp = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/grow-your-snake" element={<App />} />
        <Route path="/shoot-to-remove" element={<ShootingGame />} />
        <Route path="/merry-christmas" element={<Christmas />} />
        <Route path="/story/story-1" element={<StoryPage />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/upload" element={<ImageUpload />} />
        <Route path="/23kpos9024msflo" element={<ImageUploadAd />} />
        <Route path="/wish" element={<ValentinesDayPage />} />
        <Route path="/home-feed" element={<FeedAd />} />
      </Routes>
    </Router>
  );
};

export default RouterApp;
