import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./ValentinesDayPage.css";

export default function ValentinesDayPage() {
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const url = searchParams.get("url");
  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCreateWish = () => {
    navigate("/upload");
  };

  return (
    <div>
      <h1 className="heading">Happy Valentine's Day!</h1>
      <div className="container">
        <div className="valentines" onClick={handleClick}>
          {/* Envelope */}
          <motion.div
            className="envelope"
            animate={{
              opacity: isOpen ? 0 : 1,
              visibility: isOpen ? "hidden" : "visible",
            }}
            transition={{ duration: 0.5 }}
          ></motion.div>

          {/* Front part of the envelope */}
          <motion.div
            className="front"
            animate={{
              opacity: isOpen ? 0 : 1,
              visibility: isOpen ? "hidden" : "visible",
            }}
            transition={{ duration: 0.5 }}
          ></motion.div>

          {/* Card */}
          <motion.div
            className="card"
            animate={{
              transform: isOpen
                ? "translateY(-200px) scale(1.2)"
                : "translateY(0) scale(1)",
            }}
            transition={{ duration: 0.5 }}
          >
            {!isOpen && <div className="text">Open it !!!</div>}
            <motion.img
              src={url}
              alt="Valentine"
              className="card-image"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              animate={{
                opacity: isOpen ? 1 : 0,
                height: isOpen ? "auto" : "0px",
              }}
              transition={{ duration: 0.5 }}
            />
            <div className="heart"></div>
            {isOpen && (
              <button className="wish-button" onClick={handleCreateWish}>
                Create Your Wish
              </button>
            )}
          </motion.div>

          {/* Floating hearts */}
          <div className="hearts">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
            <div className="four"></div>
            <div className="five"></div>
          </div>
        </div>
        <div className="shadow"></div>
      </div>
    </div>
  );
}
